@import 'custom';

@import url('https://fonts.googleapis.com/css2?family=Montserrat&display=swap');
@import url('https://fonts.googleapis.com/css2?family=Caveat:wght@400&display=swap');
@import url('https://fonts.googleapis.com/css2?family=Dancing%20Script:wght@400&display=swap');
@import url('https://fonts.googleapis.com/css2?family=La%20Belle%20Aurore:wght@400&display=swap');

body {
  margin: 0;
  font-family: 'Montserrat', sans-serif;
  font-size: 16px;
  font-weight: 400;
  -webkit-font-smoothing: antialiased;
  -moz-osx-font-smoothing: grayscale;
}

code {
  font-family: source-code-pro, Menlo, Monaco, Consolas, 'Courier New', monospace;
}

::-webkit-input-placeholder {
  color: #bdbdbd !important;
}

/******  Form Styles *******/
$primary-form-color: #007bff;

textarea {
  height: auto;
}

legend.form-label {
  font-size: 1.5rem;
  text-align: center !important;
  color: desaturate(darken($primary-form-color, 10%), 60%);
}

.form-hint {
  font-size: 0.6rem;
  line-height: 1.4;
  margin: -5px auto 5px;
  color: rgb(27, 26, 26);

  &.error {
    color: #c00;
    font-size: 0.8rem;
  }
}

button.btn {
  letter-spacing: 1px;
  font-size: 0.8rem;
  font-weight: 600;
}
.auth-button {
  color: white;
  background-color: #8db033;
  width: 50%;

  &:hover {
    background-color: #007bff;
    border-color: #007bff;
    color: #fff;
  }
}

// Password strength meter color for the different levels
$strength-colors: (darkred, orangered, orange, yellowgreen, green);

// Gap width between strength meter bars
$strength-gap: 6px;

.strength-meter {
  position: relative;
  height: 3px;
  background: #ddd;
  margin: 7px 0;
  border-radius: 2px;

  // Dynamically create the gap effect
  &:before,
  &:after {
    content: '';
    height: inherit;
    background: transparent;
    display: block;
    border-color: #fff;
    border-style: solid;
    border-width: 0 $strength-gap 0;
    position: absolute;
    width: calc(20% + #{$strength-gap});
    z-index: 10;
  }

  // Dynamically create the gap effect
  &:before {
    left: calc(20% - #{($strength-gap / 2)});
  }

  // Dynamically create the gap effect
  &:after {
    right: calc(20% - #{($strength-gap / 2)});
  }
}

.strength-meter-fill {
  background: transparent;
  height: inherit;
  position: absolute;
  width: 0;
  border-radius: inherit;
  transition: width 0.5s ease-in-out, background 0.25s;

  // Dynamically generate strength meter color styles
  @for $i from 1 through 5 {
    &[data-strength='#{$i - 1}'] {
      width: (20% * $i);
      background: nth($strength-colors, $i);
    }
  }
}

.loader-container {
  position: fixed;
  top: 0;
  left: 0;
  width: 100%;
  height: 100%;
  background-color: #f8f8f8ad;
  display: flex;
  align-items: center;
  justify-content: center;
  z-index: 1010;
}

.loader {
  margin-bottom: 20%;
  align-items: center;
  z-index: 100;
  display: flex;
}

.spinner-img {
  width: 3rem;
  height: 3rem;
  color: #8db033;
}

.save-spinner-img {
  width: 3rem;
  height: 3rem;
  color: #008eff;
}

.create-listing-step {
  flex: 1;
  margin-left: -1px;

  &.border-left {
    border-left: 1px solid gray;
  }
}

@media screen and (max-width: 800px) {
  .create-listing-step {
    border-left: none;
    margin-left: 0px;
  }
}

.close {
  color: white;
  opacity: 1;
}

.modal-90w {
  width: 90%;
  max-width: none !important;
}

.modal-90wh {
  width: 90%;
  max-width: none !important;
  .modal-content {
    height: 90vh;
    overflow: auto;
  }

  @media only screen and (max-width: 767.98px) {
    width: auto;

    .modal-content {
      height: auto;
    }
  }
}

.modal-90h {
  height: 90vh;
  display: flex;
  justify-content: center;
  align-items: center;
}

input {
  color: #4f4f4f;
}

.offer1-dropdown {
  position: absolute;
  top: 95px;
  width: 140px;
  transform: translateX(-93%);
  background-color: #e9e7e7;
  border: 3px solid #3372aa;
  border-radius: 10%;
  padding: 1rem;
  overflow: hidden;
  transition: height var(--speed) ease;
}

.menu-item {
  height: 25px;
  display: flex;
  align-items: center;
  border-radius: var(--border-radius);
  transition: background var(--speed);
}

.menu-item:hover {
  background-color: #525357;
}

.is-invalid {
  color: #dc3545;
}

.swal2-container {
  z-index: 1000001;
}

.offcanvas {
  z-index: 1070;
}

.react-pdf__Page__canvas {
  margin: 0 auto;
  width: 100% !important;
  height: 100% !important;
}

.popover {
  z-index: 1000002;
}
