
$enable-negative-margins: true;
@import '~bootstrap/scss/bootstrap';
@import 'react-toastify/dist/ReactToastify';
@import 'react-datetime/css/react-datetime';

$sidebar-bg-color: #f5f5f5;
$sidebar-color: #4f4f4f;
$submenu-bg-color: #f5f5f5;
$highlight-color: #008eff;
$icon-bg-color: transparent;
@import '~react-pro-sidebar/dist/scss/styles.scss';
